<template>
  <div class="container">
    <div class="content">
      <div class="sub-head">Product Functions And Advantages</div>
      <div class="sub-des">
        With Sharekaro, you can share any your loving apps, games, videos,
        photos and anything
      </div>
    </div>
    
    <div class="swiper-container" ref="mySwiper">
        <div class="shadow-L"></div>
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in itemList"
          :key="index"
        >
          <div class="item">
            <div class="item-content">
              <div :class="['iconS', 'icon-' + item.icon]"></div>
              <div class="item-title">{{ item.title }}</div>
              <div class="item-des">{{ item.des }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="shadow-R"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  name: "Swipper",
  data() {
    return {
      itemList: [
        {
          icon: "rocket",
          title: "Super Fast, High Speed",
          des: "Speeds up to 480Mbits",
        },
        {
          icon: "lightning",
          title: "Easy to Use and Share",
          des: "Radar discover & scan QR code",
        },
        {
          icon: "pc",
          title: "Multiple Share Choice",
          des: "Share with Jio, Android, iOS, PC",
        },
        {
          icon: "offline",
          title: "No Internet needed",
          des: "Transfer files offline, save your mobile data.",
        },
        {
          icon: "files",
          title: "All types of Files",
          des: "Videos, Apps, Photos, Music, Documents, etc.",
        },
        {
          icon: "management",
          title: "Strong file management",
          des: "Manage files faster and easier.",
        },
        {
          icon: "downloader",
          title: "Video Downloader",
          des: "Download from Instagram,Facebook and Status",
        },
      ],
      currentIndex: 5,
      swiperOptions: {
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        },
        loop: true,
        //slidesPerView: "auto",
        centeredSlides: true,
        speed: 1000,
        //slidesOffsetBefore : 100,
        slidesPerView: 4.1,
        //effect : 'coverflow',
      },
    };
  },
  watch: {
    currentIndex: function () {
      return this.$refs.mySwiper ? this.$refs.mySwiper.swiper.activeIndex : 0;
    },
  },
  created() {
    this.$nextTick(() => {
      /* eslint-disable no-new */
      new Swiper(".swiper-container", { ...this.swiperOptions });
    });
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";

.container {
  //width: vw(1600);
  .content {
    //width: vw(1600);
    padding: vw(260) vw(240) 0;
    text-align: center;
    .sub-head {
      //width: vw(1000);
      font-size: vw(50);
      font-family: HelveticaNeue-Medium, HelveticaNeue;
      font-weight: 500;
      color: #2f9cf6;
      line-height: vw(61);
    }
    .sub-des {
      //width: vw(1060);
      font-size: vw(24);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: vw(32);
      margin: vw(24) 0 vw(75);
      padding: 0 vw(10);
    }
  }
  .swiper-container {
    padding-bottom: vw(115);
    .swiper-wrapper {
      //margin: 0 400px;
      //opacity: 0.5;
      //z-index: 5;

      .swiper-slide {
        //width: vw(1120px);
        //opacity: 0.5;
      }
      .swiper-slide-active {
        //width: vw(1120px);
        //opacity: 0.5;
        z-index: 10;
      }
      // .swiper-slide-pre {
      //   //width: vw(1120px);
      //   opacity: 0.5;
      //   z-index: 10;
      // }
    }
  }
  .dark {
    opacity: 0.5;
    z-index: 5;
  }
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    //flex-wrap: wrap;
    margin: 0 vw(15) 0;

    .item-content {
      width: 350px;
      height: vw(244);
      background: #f4f9ff;
      border-radius: vw(14);
      padding: vw(34) vw(35);
      text-align: center;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      .item-des {
        font-size: vw(18);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: vw(25);
      }
      .item-title {
        font-size: vw(24);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: vw(33);
        margin-top: vw(20);
      }
    }
  }
}
.shadow-L{
    width: vw(203);
    height: vw(260);
    opacity: 0.75;
    background: white;
    position: absolute;
    z-index: 20;
    //margin-top: vw(-50);
}
.shadow-R{
        width: vw(203);
    height: vw(260);
    opacity: 0.75;
    background: white;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 20;
}
@media screen and (max-width: 1080px) {
.container {
  width: minPx(1600);
  .content {
    width: minPx(1600);
    padding: minPx(260) minPx(240) 0;
    .sub-head {
      width: minPx(1000);
      font-size: minPx(50);
      line-height: minPx(61);
    }
    .sub-des {
      width: minPx(1060);
      font-size: minPx(24);
      line-height: minPx(32);
      margin: minPx(24) 0 minPx(75);
      padding: 0 minPx(10);
    }
  }
  .swiper-container {
    padding-bottom: minPx(115);
  }
  .item {
    margin: 0 minPx(15) 0;

    .item-content {
      height: minPx(244);
      border-radius: minPx(14);
      padding: minPx(34) minPx(35);
      .item-des {
        font-size: minPx(18);
        line-height: minPx(25);
      }
      .item-title {
        font-size: minPx(24);
        line-height: minPx(33);
        margin-top: minPx(20);
      }
    }
  }
}
.shadow-L{
    width: minPx(203);
    height: minPx(260);
}
.shadow-R{
        width: minPx(203);
    height: minPx(260);
}
}
@media screen and (min-width: 1601px) {
.container {
  //width: maxPx(1600);
  .content {
    //width: maxPx(1600);
    padding: maxPx(300) maxPx(240) 0;
    .sub-head {
      //width: maxPx(1000);
      font-size: maxPx(50);
      line-height: maxPx(61);
    }
    .sub-des {
      //width: maxPx(1060);
      font-size: maxPx(24);
      line-height: maxPx(32);
      margin: maxPx(24) 0 maxPx(75);
      padding: 0 maxPx(10);
    }
  }
  .swiper-container {
    padding-bottom: maxPx(115);
    //width: maxPx(1600);
  }
  .item {
    margin: 0 maxPx(15) 0;

    .item-content {
      height: maxPx(244);
      border-radius: maxPx(14);
      padding: maxPx(34) maxPx(35);
      .item-des {
        font-size: maxPx(18);
        line-height: maxPx(25);
      }
      .item-title {
        font-size: maxPx(24);
        line-height: maxPx(33);
        margin-top: maxPx(20);
      }
    }
  }
}
.shadow-L{
    width: maxPx(220);
    height: maxPx(260);
}
.shadow-R{
    width: maxPx(220);
    height: maxPx(260);
}
}
</style>
